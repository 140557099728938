export const BUY = 'buy';
export const SELL = 'sell';

export const CLICKS = 'clicks';
export const LEADS = 'leads';

export const PREPAY = 'Prepay';
export const POSTPAY = 'Postpay';

export const DAY = 'Day';
export const WEEK = 'Week';
export const MONTH = 'Month';
export const RANGE = 'Range';

export const SHARED = 'Shared';
export const EXCLUSIVE = 'Exclusive';

// Click campaign type
export const XML = 'XML Feed';
export const GEO = 'Locations';

export const STATIC_LEAD = 'Static Lead';
export const DYNAMIC_LEAD = 'Dynamic Lead';
export const STATIC_CLICK = 'Static Click';
export const DYNAMIC_CLICK = 'Dynamic Click';
export const LEAD_FORM_BUYER = 'Lead Form Buyer';

// Campaign status
export const INACTIVE = 'Inactive';
export const ACTIVE = 'Active';
export const PAUSED = 'Paused';
export const DELETED = 'Deleted';

// Lead type
export const PRIMARY = 'Primary';
export const XSELL1 = 'XSell 1st';
export const XSELL2 = 'XSell 2nd';
export const XSELL3 = 'XSell 3th';
export const XSELL4 = 'XSell 4th';

// Payment
export const CREDIT_CARD = 'Credit Card';
export const INVOICE = 'Invoice';

export const PAYMENT_FAILED = 'Payment Failed';
export const EXPIRED = 'Expired';

// Buyer status
export const CREDIT_PAUSED = 'CreditPaused';
export const CANCELLED = 'Cancelled';

// Other

export const UNHANDLED_ERROR = 'unhandled_error';
export const NON_FIELD_ERRORS = 'non_field_errors';

export const THEME_DEFAULT = 'default';
export const THEME_BL = 'bl';
export const THEME_IM = 'im';

export const CREATE = 'create';
export const EDIT = 'edit';

export const BASIC = 'basic';
export const CATEGORY = 'category';
export const SUBCATEGORY = 'subcategory';

export const STATIC = 'static';
export const DYNAMIC = 'dynamic';

export const MEGABYTE = 1048576; // (1024 * 1024 = 1048576)

export const ALL_DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const WEEKDAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
];

export const WEEKEND = ['saturday', 'sunday'];

export const DAYS_ORDER = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  'saturday-sunday': 6,
  saturday: 7,
  sunday: 8,
};

export const WEEKDAYS_COUNT = 3;

// Click types
export const CLICK_TYPE_ENHANCED = 'Enhanced Click';
export const CLICK_TYPE_ABANDONMENT = 'Abandonment - Enhanced Click';
export const CLICK_TYPE_PAYOFF = 'Payoff - Post Lead';
export const CLICK_TYPE_GROSS = 'Abandonment - Gross Click';

// Bussiness types

export const BUSSINESS_TYPE_ENTERPRISE = 'enterprise';
export const BUSSINESS_TYPE_RETAIL = 'retail';
export const BUSSINESS_TYPE_MID_MARKET = 'mid_market';
